import React from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";

const Homepage = React.lazy(() => import("./Homepage"));
const BlogPage = React.lazy(() => import("./BlogPage"));

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/blog" element={<BlogPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
